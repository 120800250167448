import React from 'react'
import { useState ,useEffect} from 'react';
function CurrentTime() {
    const [currentTime, setCurrentTime] = useState(new Date());
    useEffect(() => {
        const timer = setInterval(() => {
          setCurrentTime(new Date());
        }, 1000);
    
        return () => clearInterval(timer); // Clean up the interval on component unmount
      }, []);


      const formatTime = (time) => {
        return time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
      };
  return (
    
      <div className="h-20 flex justify-center items-center  text-center flex-col ">
        <div className="">
          <div className="text-xl font-bold mb-4 ">{currentTime.toDateString()} {formatTime(currentTime)}</div>
        </div>
        {/* <h1 className="text-white text-2xl font-bold">हा भाई यही आती हे सबसे पहले खबर रूको और देखो</h1> */}
      </div>
    
  )
}

export default CurrentTime
