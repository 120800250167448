// import Navbar from "../Navbar/Navbar";
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { EditorState, convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";

// const API_URL = process.env.REACT_APP_DNAME;

// function Blog() {
//   const [categories, setCategories] = useState([]);
//   const [blogs, setBlogs] = useState([]); 
//   const [formData, setFormData] = useState({
//     slug: "",
//     title: "",
//     blogPost: "",
//     category: "",
//     metaTitle: "",
//     metaDescription: "",
//     imageLink: "",
//     featuredImageAlt: "",
//     sitemapPriority: "",
//     sitemapChangefreq: "Always",
//   });
//   const [editorState, setEditorState] = useState(EditorState.createEmpty());

//   useEffect(() => {
//     axios
//       .get(`${API_URL}/get-categories`)
//       .then((response) => setCategories(response.data))
//       .catch((error) => toast.error("Failed to load categories"));
//   }, []);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };


//     // Function to fetch blogs
//     const fetchBlogs = async () => {
//      await axios
//         .get(`${API_URL}/get-blogs`)
//         .then((response) => setBlogs(response.data))
//         .catch(() => toast.error("Failed to load blogs"));
//     };


//     useEffect(()=>{
//       fetchBlogs()
//     },[])
  
//     // Function to handle delete
//     const handleDelete = (id) => {
//       axios
//         .delete(`${API_URL}/delete-blog/${id}`)
//         .then(() => {
//           toast.success("Blog deleted successfully");
//           fetchBlogs(); // Refresh blogs after deletion
//         })
//         .catch(() => toast.error("Failed to delete blog"));
//     };
  
//     // Placeholder function to handle edit
//     const handleEdit = (id) => {
//       toast.info("Edit functionality not yet implemented");
//     };

//   const onEditorStateChange = (newState) => {
//     setEditorState(newState);
//     setFormData({
//       ...formData,
//       blogPost: draftToHtml(convertToRaw(newState.getCurrentContent())),
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     axios
//       .post(`${API_URL}/create-blog`, formData)
//       .then((response) =>
//         toast.success("Blog submitted successfully", response)
//       )
//       .catch((error) => toast.error("Failed to submit blog", error));
//   };

//   return (
//     <>
//       <div className="bg-gray-100 min-h-screen p-8">
//         <ToastContainer />
//         <Navbar />
//         <div className="max-w-4xl mt-4 mx-auto bg-white p-6 rounded-lg shadow-md">
//           <h1 className="text-3xl font-semibold text-center text-gray-700 mb-6">
//             Create a Blog Post
//           </h1>
//           <form onSubmit={handleSubmit} className="space-y-4">
//             <div>
//               <label className="block text-gray-600 font-medium mb-2">
//                 Slug
//               </label>
//               <input
//                 type="text"
//                 name="slug"
//                 value={formData.slug}
//                 onChange={handleChange}
//                 required
//                 className="border p-2 rounded w-full"
//                 placeholder="Enter the slug"
//               />
//             </div>

//             <div>
//               <label className="block text-gray-600 font-medium mb-2">
//                 Title
//               </label>
//               <input
//                 type="text"
//                 name="title"
//                 value={formData.title}
//                 onChange={handleChange}
//                 required
//                 className="border p-2 rounded w-full"
//                 placeholder="Enter the title"
//               />
//             </div>

//             <div>
//               <label className="block text-gray-600 font-medium mb-2">
//                 Blog Post
//               </label>
//               <Editor
//                 editorState={editorState}
//                 toolbarClassName="toolbarClassName"
//                 wrapperClassName="wrapperClassName"
//                 editorClassName="editorClassName border border-black bg-slate-200"
//                 onEditorStateChange={onEditorStateChange}
//               />
//             </div>

//             <div>
//               <label className="block text-gray-600 font-medium mb-2">
//                 Category
//               </label>
//               <select
//                 name="category"
//                 value={formData.category}
//                 onChange={handleChange}
//                 required
//                 className="border p-2 rounded w-full"
//               >
//                 <option value="">Select Category</option>
//                 {categories.map((cat) => (
//                   <option key={cat._id} value={cat._id}>
//                     {cat.name}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             <div>
//               <label className="block text-gray-600 font-medium mb-2">
//                 Meta Title
//               </label>
//               <input
//                 type="text"
//                 name="metaTitle"
//                 value={formData.metaTitle}
//                 onChange={handleChange}
//                 className="border p-2 rounded w-full"
//                 placeholder="Enter meta title"
//               />
//             </div>

//             <div>
//               <label className="block text-gray-600 font-medium mb-2">
//                 Meta Description
//               </label>
//               <input
//                 type="text"
//                 name="metaDescription"
//                 value={formData.metaDescription}
//                 onChange={handleChange}
//                 className="border p-2 rounded w-full"
//                 placeholder="Enter meta description"
//               />
//             </div>

//             <div>
//               <label className="block text-gray-600 font-medium mb-2">
//                 Image Link
//               </label>
//               <input
//                 type="text"
//                 name="imageLink"
//                 value={formData.imageLink}
//                 onChange={handleChange}
//                 className="border p-2 rounded w-full"
//                 placeholder="Enter image URL"
//               />
//             </div>

//             <div>
//               <label className="block text-gray-600 font-medium mb-2">
//                 Featured Image Alt Attribute
//               </label>
//               <input
//                 type="text"
//                 name="featuredImageAlt"
//                 value={formData.featuredImageAlt}
//                 onChange={handleChange}
//                 className="border p-2 rounded w-full"
//                 placeholder="Enter alt text for featured image"
//               />
//             </div>

//             <div>
//               <label className="block text-gray-600 font-medium mb-2">
//                 Sitemap Priority
//               </label>
//               <input
//                 type="text"
//                 name="sitemapPriority"
//                 value={formData.sitemapPriority}
//                 onChange={handleChange}
//                 className="border p-2 rounded w-full"
//                 placeholder="Enter sitemap priority"
//               />
//             </div>

//             <div>
//               <label className="block text-gray-600 font-medium mb-2">
//                 Sitemap Changefreq
//               </label>
//               <select
//                 name="sitemapChangefreq"
//                 value={formData.sitemapChangefreq}
//                 onChange={handleChange}
//                 className="border p-2 rounded w-full"
//               >
//                 <option value="Always">Always</option>
//                 <option value="Hourly">Hourly</option>
//                 <option value="Daily">Daily</option>
//                 <option value="Weekly">Weekly</option>
//                 <option value="Monthly">Monthly</option>
//                 <option value="Yearly">Yearly</option>
//               </select>
//             </div>

//             <button
//               type="submit"
//               className="mt-6 w-full bg-blue-500 hover:bg-blue-600 text-white p-2 rounded"
//             >
//               Submit Blog Post
//             </button>
//           </form>
//         </div>
//       </div>
//       <div className="max-w-4xl mt-8 mx-auto bg-white p-6 rounded-lg shadow-md">
//           <h2 className="text-2xl font-semibold text-center text-gray-700 mb-4">
//             Existing Blogs
//           </h2>
//           <table className="w-full text-left border-collapse">
//             <thead>
//               <tr>
//                 <th className="border-b p-3 font-semibold text-gray-700">Sr No</th>
//                 <th className="border-b p-3 font-semibold text-gray-700">Title</th>
//                 <th className="border-b p-3 font-semibold text-gray-700">Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {blogs.map((blog, index) => (
//                 <tr key={blog._id}>
//                   <td className="border-b p-3">{index + 1}</td>
//                   <td className="border-b p-3">{blog.title}</td>
//                   <td className="border-b p-3">
//                     <button
//                       onClick={() => handleEdit(blog._id)}
//                       className="text-blue-500 mr-4 hover:underline"
//                     >
//                       Edit
//                     </button>
//                     <button
//                       onClick={() => handleDelete(blog._id)}
//                       className="text-red-500 hover:underline"
//                     >
//                       Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//     </>
//   );
// }

// export default Blog;








import Navbar from "../Navbar/Navbar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const API_URL = process.env.REACT_APP_DNAME;

function Blog() {
  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]); 
  const [formData, setFormData] = useState({
    slug: "",
    title: "",
    blogPost: "",
    category: "",
    metaTitle: "",
    metaDescription: "",
    imageLink: "",
    featuredImageAlt: "",
    sitemapPriority: "",
    sitemapChangefreq: "Always",
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editMode, setEditMode] = useState(false); // Track if editing
  const [editBlogId, setEditBlogId] = useState(null); // Track ID of blog being edited

  useEffect(() => {
    axios
      .get(`${API_URL}/get-categories`)
      .then((response) => setCategories(response.data))
      .catch((error) => toast.error("Failed to load categories"));
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to fetch blogs
  const fetchBlogs = async () => {
    await axios
      .get(`${API_URL}/get-blogs`)
      .then((response) => setBlogs(response.data))
      .catch(() => toast.error("Failed to load blogs"));
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  // Function to handle delete
  const handleDelete = (id) => {
    axios
      .delete(`${API_URL}/delete-blog/${id}`)
      .then(() => {
        toast.success("Blog deleted successfully");
        fetchBlogs(); // Refresh blogs after deletion
      })
      .catch(() => toast.error("Failed to delete blog"));
  };

  // Function to handle edit
  const handleEdit = (id) => {
    const blogToEdit = blogs.find((blog) => blog._id === id);
    if (blogToEdit) {
      const contentBlock = htmlToDraft(blogToEdit.blogPost || "");
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      setFormData({
        slug: blogToEdit.slug,
        title: blogToEdit.title,
        blogPost: blogToEdit.blogPost,
        category: blogToEdit.category,
        metaTitle: blogToEdit.metaTitle,
        metaDescription: blogToEdit.metaDescription,
        imageLink: blogToEdit.imageLink,
        featuredImageAlt: blogToEdit.featuredImageAlt,
        sitemapPriority: blogToEdit.sitemapPriority,
        sitemapChangefreq: blogToEdit.sitemapChangefreq,
      });
      setEditorState(editorState);
      setEditMode(true);
      setEditBlogId(id);
      toast.info("Edit mode enabled");
    }
  };

  const onEditorStateChange = (newState) => {
    setEditorState(newState);
    setFormData({
      ...formData,
      blogPost: draftToHtml(convertToRaw(newState.getCurrentContent())),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editMode) {
      // Update existing blog
      axios
        .put(`${API_URL}/update-blog/${editBlogId}`, formData)
        .then(() => {
          toast.success("Blog updated successfully");
          fetchBlogs();
          setEditMode(false);
          setEditBlogId(null);
          setFormData({
            slug: "",
            title: "",
            blogPost: "",
            category: "",
            metaTitle: "",
            metaDescription: "",
            imageLink: "",
            featuredImageAlt: "",
            sitemapPriority: "",
            sitemapChangefreq: "Always",
          });
          setEditorState(EditorState.createEmpty());
        })
        .catch(() => toast.error("Failed to update blog"));
    } else {
      // Create new blog
      axios
        .post(`${API_URL}/create-blog`, formData)
        .then(() => {
          toast.success("Blog submitted successfully");
          fetchBlogs();
          setFormData({
            slug: "",
            title: "",
            blogPost: "",
            category: "",
            metaTitle: "",
            metaDescription: "",
            imageLink: "",
            featuredImageAlt: "",
            sitemapPriority: "",
            sitemapChangefreq: "Always",
          });
          setEditorState(EditorState.createEmpty());
        })
        .catch(() => toast.error("Failed to submit blog"));
    }
  };

  return (
    <>
        <Navbar />
      <div className="bg-gray-100 min-h-screen p-8 md:ml-[200px]">
        <ToastContainer />
        <div className="max-w-4xl mt-4 mx-auto bg-white p-6 rounded-lg shadow-md">
          <h1 className="text-3xl font-semibold text-center text-gray-700 mb-6">
            {editMode ? "Edit Blog Post" : "Create a Blog Post"}
          </h1>
          <form onSubmit={handleSubmit} className="space-y-4">
          <div>
              <label className="block text-gray-600 font-medium mb-2">
                Slug
              </label>
              <input
                type="text"
                name="slug"
                value={formData.slug}
                onChange={handleChange}
                required
                className="border p-2 rounded w-full"
                placeholder="Enter the slug"
              />
            </div>

            <div>
              <label className="block text-gray-600 font-medium mb-2">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                className="border p-2 rounded w-full"
                placeholder="Enter the title"
              />
            </div>

            <div>
              <label className="block text-gray-600 font-medium mb-2">
                Blog Post
              </label>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName border border-black bg-slate-200"
                onEditorStateChange={onEditorStateChange}
              />
            </div>

            <div>
              <label className="block text-gray-600 font-medium mb-2">
                Category
              </label>
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
                className="border p-2 rounded w-full"
              >
                <option value="">Select Category</option>
                {categories.map((cat) => (
                  <option key={cat._id} value={cat._id}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-gray-600 font-medium mb-2">
                Meta Title
              </label>
              <input
                type="text"
                name="metaTitle"
                value={formData.metaTitle}
                onChange={handleChange}
                className="border p-2 rounded w-full"
                placeholder="Enter meta title"
              />
            </div>

            <div>
              <label className="block text-gray-600 font-medium mb-2">
                Meta Description
              </label>
              <input
                type="text"
                name="metaDescription"
                value={formData.metaDescription}
                onChange={handleChange}
                className="border p-2 rounded w-full"
                placeholder="Enter meta description"
              />
            </div>

            <div>
              <label className="block text-gray-600 font-medium mb-2">
                Image Link
              </label>
              <input
                type="text"
                name="imageLink"
                value={formData.imageLink}
                onChange={handleChange}
                className="border p-2 rounded w-full"
                placeholder="Enter image URL"
              />
            </div>

            <div>
              <label className="block text-gray-600 font-medium mb-2">
                Featured Image Alt Attribute
              </label>
              <input
                type="text"
                name="featuredImageAlt"
                value={formData.featuredImageAlt}
                onChange={handleChange}
                className="border p-2 rounded w-full"
                placeholder="Enter alt text for featured image"
              />
            </div>

            <div>
              <label className="block text-gray-600 font-medium mb-2">
                Sitemap Priority
              </label>
              <input
                type="text"
                name="sitemapPriority"
                value={formData.sitemapPriority}
                onChange={handleChange}
                className="border p-2 rounded w-full"
                placeholder="Enter sitemap priority"
              />
            </div>

            <div>
              <label className="block text-gray-600 font-medium mb-2">
                Sitemap Changefreq
              </label>
              <select
                name="sitemapChangefreq"
                value={formData.sitemapChangefreq}
                onChange={handleChange}
                className="border p-2 rounded w-full"
              >
                <option value="Always">Always</option>
                <option value="Hourly">Hourly</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
            </div>
            <button
              type="submit"
              className="mt-6 w-full bg-blue-500 hover:bg-blue-600 text-white p-2 rounded"
            >
              {editMode ? "Update Blog Post" : "Submit Blog Post"}
            </button>
          </form>
        </div>
      </div>
      <div className="max-w-4xl mt-8 mx-auto bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-center text-gray-700 mb-4">
          Existing Blogs
        </h2>
        <table className="w-full text-left border-collapse">
          <thead>
            <tr>
              <th className="border-b p-3 font-semibold text-gray-700">Sr No</th>
              <th className="border-b p-3 font-semibold text-gray-700">Title</th>
              <th className="border-b p-3 font-semibold text-gray-700">Actions</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((blog, index) => (
              <tr key={blog._id}>
                <td className="border-b p-3">{index + 1}</td>
                <td className="border-b p-3">{blog.title}</td>
                <td className="border-b p-3">
                  <button
                    onClick={() => handleEdit(blog._id)}
                    className="text-blue-500 mr-4 hover:underline"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(blog._id)}
                    className="text-red-500 hover:underline"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Blog;
