import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_URL = process.env.REACT_APP_DNAME; // Ensure to add your API URL in .env

function PostCategory() {
  const [categoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [editingCategory, setEditingCategory] = useState(null); // State for editing

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fetch existing categories
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/get-categories`);
      if (response.data) {
        setCategories(response.data);
      }
    } catch (error) {
      toast.error('Error fetching categories');
    }
  };

  // Add or Edit Category
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form
    if (!categoryName) {
      setErrors({ categoryName: 'Category name is required' });
      return;
    }

    try {
      // If editingCategory is set, update category, otherwise add a new category
      if (editingCategory) {
        const response = await axios.put(`${API_URL}/edit-category/${editingCategory._id}`, {
          name: categoryName,
        });
        if (response.data) {
          toast.success('Category updated successfully');
        }
      } else {
        const response = await axios.post(`${API_URL}/add-category`, { name: categoryName });
        if (response.data) {
          toast.success('Category added successfully');
        }
      }
      
      setCategoryName(''); // Clear the input field
      setEditingCategory(null); // Clear editing state
      fetchCategories(); // Refresh the category list
    } catch (error) {
      toast.error('Error submitting category');
    }
  };

  // Set up the category for editing
  const editCategory = (category) => {
    setEditingCategory(category); // Set the category to be edited
    setCategoryName(category.name); // Prefill the input with the category name
    setErrors({}); // Clear any previous errors
  };

  // Cancel edit mode
  const cancelEdit = () => {
    setEditingCategory(null); // Clear editing state
    setCategoryName(''); // Clear input field
  };

  // Delete category
  const deleteCategory = async (id) => {
    try {
      await axios.delete(`${API_URL}/delete-category/${id}`);
      toast.success('Category deleted successfully');
      fetchCategories(); // Refresh the category list
    } catch (error) {
      toast.error('Error deleting category');
    }
  };

  return (
    <div>
      <Navbar />
      <div className='md:ml-[200px]'>
      <div className='p-4 max-w-4xl mx-auto '>
        <h2 className='text-xl font-bold mb-4'>{editingCategory ? 'Edit Post Category' : 'Add Post Category'}</h2>
        
        {/* Form to add or edit category */}
        <form onSubmit={handleSubmit} className='mb-6'>
          <div className='mb-4'>
            <label className='block text-sm font-bold mb-2'>Post Category Name</label>
            <input
              type='text'
              className='w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500'
              value={categoryName}
              onChange={(e) => {
                setCategoryName(e.target.value);
                setErrors({});
              }}
              placeholder='Enter category name'
            />
            {errors.categoryName && <p className='text-red-600'>{errors.categoryName}</p>}
          </div>

          <div className='flex'>
            <button
              type='submit'
              className='w-full md:w-auto px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600'
            >
              {editingCategory ? 'Update Category' : 'Add Category'}
            </button>

            {editingCategory && (
              <button
                type='button'
                className='ml-2 w-full md:w-auto px-4 py-2 bg-gray-500 text-white font-semibold rounded hover:bg-gray-600'
                onClick={cancelEdit}
              >
                Cancel
              </button>
            )}
          </div>
        </form>

        <h2 className='text-xl font-bold mb-4'>Existing Categories</h2>
        <div className='overflow-x-auto'>
          <table className='table-auto w-full border-collapse border border-gray-400'>
            <thead>
              <tr className='bg-gray-100'>
                <th className='border border-gray-400 px-4 py-2'>Sr. No.</th>
                <th className='border border-gray-400 px-4 py-2'>Category Name</th>
                <th className='border border-gray-400 px-4 py-2'>Edit</th>
                <th className='border border-gray-400 px-4 py-2'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {categories.length > 0 ? (
                categories.map((category, index) => (
                  <tr key={category._id} className='text-center'>
                    <td className='border border-gray-400 px-4 py-2'>{index + 1}</td>
                    <td className='border border-gray-400 px-4 py-2'>{category.name}</td>
                    <td className='border border-gray-400 px-4 py-2'>
                      <button
                        onClick={() => editCategory(category)}
                        className='text-blue-500 hover:underline'
                      >
                        Edit
                      </button>
                    </td>
                    <td className='border border-gray-400 px-4 py-2'>
                      <button
                        onClick={() => deleteCategory(category._id)}
                        className='text-red-500 hover:underline'
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='4' className='text-center p-4'>No categories found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default PostCategory;
