import { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FaHome, FaGamepad, FaAd, FaClipboardList, FaEdit, FaSignOutAlt, FaBars } from 'react-icons/fa';

const Sidebar = ({ update }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeInfo, setActiveInfo] = useState('Home');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    switch (location.pathname) {
      case '/admin':
        setActiveInfo('Home');
        break;
      case '/admin/games':
        setActiveInfo('Games');
        break;
      case '/admin/games-result':
        setActiveInfo('Games Result');
        break;
      case '/admin/ad-manager':
        setActiveInfo('Ad Manager');
        break;
      case '/post-category':
        setActiveInfo('Post Category');
        break;
      case '/create-post':
        setActiveInfo('Create Post');
        break;
      case '/':
        setActiveInfo('Logout');
        break;
      default:
        setActiveInfo('');
    }
  }, [location.pathname]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const activeClass = "text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 mb-2 flex items-center";
  const defaultClass = "text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 mb-2 flex items-center";

  return (
    <>
      {/* Sidebar Toggle Button */}
      <div className="flex md:hidden justify-between items-center p-4 bg-blue-600">
        <button onClick={toggleSidebar} className="text-white text-2xl">
          <FaBars />
        </button>
        <h2 className="text-white font-semibold text-lg">Admin Dashboard</h2>
      </div>

      {/* Sidebar */}
      <div className={`fixed top-0 left-0 h-full bg-gray-800 text-white transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:translate-x-0 md:w-64 z-50`}>
        <div className="p-6">
          <h2 className="text-2xl font-semibold mb-6">Admin Dashboard</h2>
          <NavLink className={({ isActive }) => (isActive ? activeClass : defaultClass)} to='/admin' end>
            <FaHome className="mr-2" /> Home
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? activeClass : defaultClass)} to='/admin/games'>
            <FaGamepad className="mr-2" /> Games
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? activeClass : defaultClass)} to='/admin/games-result'>
            <FaClipboardList className="mr-2" /> Games Result
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? activeClass : defaultClass)} to='/admin/ad-manager'>
            <FaAd className="mr-2" /> Ad Manager
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? activeClass : defaultClass)} to='/admin/post-category'>
            <FaClipboardList className="mr-2" /> Post Category
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? activeClass : defaultClass)} to='/admin/create-post'>
            <FaEdit className="mr-2" /> Create Post
          </NavLink>
          <NavLink 
  className={({ isActive }) => (isActive ? activeClass : defaultClass)} 
  to="/" 
  onClick={(e) => {
    e.preventDefault(); // Prevent default navigation
    window.open("/", "_blank"); // Open in new tab
  }}
>
  <FaEdit className="mr-2" /> Website
</NavLink>

          <NavLink className={({ isActive }) => (isActive ? activeClass : defaultClass)} to='/' onClick={() => { update(); localStorage.clear(); }}>
            <FaSignOutAlt className="mr-2" /> Logout
          </NavLink>
        </div>
      </div>

      {/* Overlay for Mobile */}
      {isSidebarOpen && <div onClick={toggleSidebar} className="fixed inset-0 bg-black opacity-50 md:hidden z-40"></div>}

      {/* Main Content */}
      <div className={`ml-0 md:ml-64 p-6 transition-all duration-300 ease-in-out`}>
        <div className=' text-center font-bold text-xl resulttableIstcol rounded p-4 border border-slate-400'>
          {activeInfo}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
