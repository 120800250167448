import React from "react";
import { Helmet } from "react-helmet";
// import { Toaster } from "react-hot-toast";
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
const Layout = ({ children, title, description, keywords, author,image }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="twitter:image" content={image} />
        <meta name="robots" content="index, follow"/>
        <meta property="og:locale" content="en_US" />
        <link hreflang="en-us" href="https://lucky-satta.co" />
        <meta property="og:site_name" content="lucky-satta" />
        <meta property="og:title" content="lucky-satta" />
        <meta property="og:url" content="https://lucky-satta.co" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Get Daily Lucky Satta results like Gali, Desawar, Faridabad, and Ghaziabad. Check your Lucky Satta, Satta King, and Delhi Bazar Satta outcomes superfast. Monthly results for Desawar, and more on Lucky-Satta.co for quick, reliable updates." />
        <meta name="google-site-verification" content="c_DElx9OdC7lSV79zG697nq6Nh4xXNTqHhfmNBHaIQ8" />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <Navbar/>
      <main className="" >
        {/* <Toaster /> */}
        {children}
      </main>
      {/* <Footer/> */}
    </div>
  );
};

Layout.defaultProps = {
  title: "LUCKY SATTA | DAILY LUCKY SATTA RESULT | DELHI FARIDABAD GHAZIABAD GALI",
  discription: "Get Daily Lucky Satta results like Gali, Desawar, Faridabad, and Ghaziabad. Check your Lucky Satta, Satta King, and Delhi Bazar Satta outcomes superfast. Monthly results for Desawar, and more on Lucky-Satta.co for quick, reliable updates.",
  keywords: "Lucky satta, lucky-satta, lucky-satta.co, luckysatta, lucky number, delhi bazar satta, a1satta, satta king, shri ganesh satta, gali satta, today faridabad satta, सट्टा किंग",
  author: "Lucy-Satta",
  image :"/images/share-image.jpg"
};

export default Layout;
