// import React from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';

// function Navbar() {
//     const navigate = useNavigate();
//     const location = useLocation();

//     const getLogin = () => {
//         const loggedIn = localStorage.getItem('loggedIn');
//         if (loggedIn) {
//             navigate('/admin-login');
//         } else {
//             navigate('/admin-login');
//         }
//     };

//     const isActive = (path) => location.pathname === path;

//     const navigateToChart = () => {
//         const currentDate = new Date();
//         const month = currentDate.toLocaleString('default', { month: 'long' }).toLowerCase(); // Convert to lowercase
//         const year = currentDate.getFullYear();
//         navigate(`/custom-monthly-chart/${month}-${year}`);
//     };

//     return (
//         <div className='shadow-2xl'>
//             <div className='flex justify-evenly gap-2 p-4'>
//                 <div 
//                     onClick={() => { navigate('/') }}  
//                     className={`py-1 font-bold border border-slate-600 hover:cursor-pointer hover:bg-slate-100 w-36 ${isActive('/') ? 'bg-orange-400' : 'bg-yellow-300'} text-bold text-xs md:text-lg rounded-lg text-center`}
//                 >
//                     Home
//                 </div>
//                 <div 
//                     onClick={navigateToChart} 
//                     className={`py-1 font-bold border border-slate-600 hover:cursor-pointer hover:bg-slate-100 w-36 ${isActive('/user/custom-monthly-chart') ? 'bg-orange-400' : 'bg-yellow-300'} text-bold text-xs md:text-lg rounded-lg text-center`}
//                 >
//                     Chart
//                 </div>
//                 <div 
//                     onClick={() => { navigate('/contact-us') }}  
//                     className={`py-1 font-bold border border-slate-600 hover:cursor-pointer hover:bg-slate-100 w-36 ${isActive('/user/contact-us') ? 'bg-orange-400' : 'bg-yellow-300'} text-bold text-xs md:text-lg rounded-lg text-center`}
//                 >
//                     Contact
//                 </div>
//                 <div 
//                     onClick={getLogin} 
//                     className={`py-1 font-bold border border-slate-600 hover:cursor-pointer hover:bg-slate-100 w-36 ${isActive('/admin') || isActive('/admin/') ? 'bg-orange-400' : 'bg-yellow-300'} text-bold text-xs md:text-lg rounded-lg text-center`}
//                 >
//                     Login
//                 </div>
//             </div>
//             <div className="p-2 h-14">
//                 <marquee>
//                     <p className='text-sm font-bold'>Daily Superfast Lucky Satta Results and Leak Numbers for Gali, Desawar, Ghaziabad, and Faridabad, including the Complete Lucky Satta Charts for 2020-2024, as well as results from Lucky Satta Fast, Lucky Satta Desawar 2019, and Lucky Satta Fast Results.</p>
//                 </marquee>
//             </div>
//             <div className='h-20 heading font-bold text-2xl flex justify-center items-center'>
//                 LUCKY-SATTA
//             </div>
            
//         </div>
//     );
// }

// export default Navbar;













import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Navbar() {
    const navigate = useNavigate();
    const location = useLocation();

    const getLogin = (e) => {
        e.preventDefault();
        const loggedIn = localStorage.getItem('loggedIn');
        navigate('/admin-login');
    };

    const isActive = (path) => location.pathname === path;

    const navigateToChart = (e) => {
        e.preventDefault();
        const currentDate = new Date();
        const month = currentDate.toLocaleString('default', { month: 'long' }).toLowerCase(); // Convert to lowercase
        const year = currentDate.getFullYear();
        navigate(`/custom-monthly-chart/${month}-${year}`);
    };

    return (
        <div className='shadow-2xl'>
            <div className='flex justify-evenly gap-2 p-4'>
                <a 
                    href="/" 
                    onClick={(e) => { e.preventDefault(); navigate('/') }}  
                    className={`py-1 font-bold border border-slate-600 hover:cursor-pointer hover:bg-slate-100 w-36 ${isActive('/') ? 'bg-orange-400' : 'bg-yellow-300'} text-bold text-xs md:text-lg rounded-lg text-center`}
                >
                    Home
                </a>
                <a 
                    href="/custom-monthly-chart" 
                    onClick={navigateToChart} 
                    className={`py-1 font-bold border border-slate-600 hover:cursor-pointer hover:bg-slate-100 w-36 ${isActive('/user/custom-monthly-chart') ? 'bg-orange-400' : 'bg-yellow-300'} text-bold text-xs md:text-lg rounded-lg text-center`}
                >
                    Chart
                </a>
                <a 
                    href="/contact-us" 
                    onClick={(e) => { e.preventDefault(); navigate('/contact-us') }}  
                    className={`py-1 font-bold border border-slate-600 hover:cursor-pointer hover:bg-slate-100 w-36 ${isActive('/user/contact-us') ? 'bg-orange-400' : 'bg-yellow-300'} text-bold text-xs md:text-lg rounded-lg text-center`}
                >
                    Contact
                </a>
                <a 
                    href="/admin-login" 
                    onClick={getLogin} 
                    className={`py-1 font-bold border border-slate-600 hover:cursor-pointer hover:bg-slate-100 w-36 ${isActive('/admin') || isActive('/admin/') ? 'bg-orange-400' : 'bg-yellow-300'} text-bold text-xs md:text-lg rounded-lg text-center`}
                >
                    Login
                </a>
            </div>
            <div className="p-2 h-14">
                <marquee>
                    <p className='text-sm font-bold'>Daily Superfast Lucky Satta Results and Leak Numbers for Gali, Desawar, Ghaziabad, and Faridabad, including the Complete Lucky Satta Charts for 2020-2024, as well as results from Lucky Satta Fast, Lucky Satta Desawar 2019, and Lucky Satta Fast Results.</p>
                </marquee>
            </div>
            <div className='h-20 heading font-bold text-2xl flex justify-center items-center'>
                LUCKY-SATTA
            </div>
            
        </div>
    );
}

export default Navbar;
