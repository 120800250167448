import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Layout from '../Layout/Layout';

const API_URL = process.env.REACT_APP_DNAME;

function BlogDetail() {
  const { slug } = useParams();
  const location = useLocation();
  const [blog, setBlog] = useState(location.state?.data || null);

  useEffect(() => {
    // If blog data is not passed through state, fetch it by slug
    if (!blog) {
      const fetchBlog = async () => {
        try {
          const response = await axios.get(`${API_URL}/get-blog/${slug}`);
          setBlog(response.data);
        } catch (error) {
          toast.error('Failed to load blog details');
        }
      };
      fetchBlog();
    }
  }, [slug, blog]);

  if (!blog) return <p>Loading...</p>;

  return (
    <Layout title={blog.title} description={blog.metaDescription} image={blog.imageLink}>
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="blog-detail max-w-3xl p-6 bg-white shadow-lg transform transition-all hover:shadow-2xl hover:-translate-y-2 rounded-lg overflow-hidden">
        <h1 className="text-3xl font-bold mb-4 text-center text-gray-800">{blog.title}</h1>
        <div className="flex justify-center mb-4">
          <img className="h-80 rounded-lg shadow-md" src={blog.imageLink} alt={blog.featuredImageAlt} />
        </div>
        <div className="text-gray-700 text-lg leading-relaxed" dangerouslySetInnerHTML={{ __html: blog.blogPost }}></div>
      </div>
    </div>
      </Layout>
  );
}

export default BlogDetail;
