import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../Footer/Footer';
import Layout from '../Layout/Layout';

const DNAME = process.env.REACT_APP_DNAME;

function CustomMonthResult() {
  const { month } = useParams();
  const navigate = useNavigate();
  const [games, setGames] = useState([]);
  const [monthlyResults, setMonthlyResults] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    getGames();
    const initialDate = parseMonthYear(month);
    setSelectedDate(initialDate);
    getResultByMonth(initialDate);
  }, [month]);

  const getGames = async () => {
    try {
      const result = await axios.get(`${DNAME}/getgames`);
      if (result && result.data) {
        setGames(result.data);
      } else {
        setGames([]);
      }
    } catch (error) {
      console.error('Error fetching games:', error);
      toast.error("Error fetching games");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const parseMonthYear = (monthYearString) => {
    const [monthName, year] = monthYearString.split('-');
    const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();
    return new Date(year, monthIndex);
  };

  const resultsByDate = monthlyResults?.reduce((acc, curr) => {
    const date = formatDate(curr.date);
    if (!acc[date]) {
      acc[date] = {};
    }
    acc[date][curr.gameId] = curr.result;
    return acc;
  }, {}) || {};

  const dates = Object.keys(resultsByDate).sort();

  const getResultByMonth = async (date) => {
    try {
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const result = await axios.get(`${DNAME}/getgameresultsbymonth`, {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      });
      if (result && result.data) {
        setMonthlyResults(result.data);
      }
    } catch (error) {
      console.error('Error fetching game results by month:', error);
      toast.error('Error fetching game results by month');
    }
  };

  const handleDateChange = (event) => {
    const [year, month] = event.target.value.split('-');
    const newDate = new Date(year, month - 1);
    setSelectedDate(newDate);
    const formattedMonth = newDate.toLocaleString('default', { month: 'long' }).toLowerCase(); // Convert to lowercase
    navigate(`/custom-monthly-chart/${formattedMonth}-${year}`);
  };

  // Format the month name and year for the heading
  const formattedMonthYear = selectedDate.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  }).toLowerCase(); // Convert to lowercase

  return (
    <Layout title="Lucky-Satta Custom Month Result">
      <div className="p-4 font-bold text-4xl border-2 border-white heading flex justify-center items-center">
        <input
          type="month"
          value={`${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}`}
          onChange={handleDateChange}
          className="text-black p-2 rounded"
        />
      </div>
      <div className='overflow-x-auto'>
        <table className="border-collapse w-full table-auto">
          <thead>
            <tr className="resulttableIstcol text-center border border-slate-400 p-2 text-2xl">
              <th colSpan="20">
                {formattedMonthYear} Monthly Result Chart
              </th>
            </tr>
            <tr>
              <th className="resulttableIstcol border border-slate-400 p-2">Date</th>
              {games?.map((game, index) => (
                <th key={index} className="border border-slate-400 p-2 resulttableIstcol">{game.code}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dates.map((date, index) => (
              <tr key={index}>
                <td className="border border-slate-400 p-2 text-center resulttableIstcol">{date}</td>
                {games?.map((game, idx) => (
                  <td key={idx} className="border border-slate-400 p-2 text-center">
                    {resultsByDate[date] && resultsByDate[date][game._id] ? resultsByDate[date][game._id] : '-'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
      <ToastContainer />
    </Layout>
  );
}

export default CustomMonthResult;
