import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../Footer/Footer';
import Layout from '../Layout/Layout';
import GameDescription from '../GameDescription/GameDescription';

const DNAME = process.env.REACT_APP_DNAME;

function SingleGameChart() {
  const { month } = useParams();
  const data = useParams();
  const gameSlug = data['game-chart'];

  // Slugify function
  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  };

  // Reverse slugify function to get the original game name
  const deslugify = (slug) => {
    return slug
      .replace(/-/g, ' ')  // Replace hyphens with spaces
      .replace(/\b\w/g, char => char.toUpperCase());  // Capitalize first letter of each word
  };

  const gameName = deslugify(gameSlug);
  const navigate = useNavigate();
  const [filteredGame, setFilteredGame] = useState(null);
  const [monthlyResults, setMonthlyResults] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    getFilteredGame();
    const initialDate = parseMonthYear(month);
    setSelectedDate(initialDate);
    getResultByMonth(initialDate);
  }, [month, data]);

  const getFilteredGame = async () => {
    try {
      const result = await axios.get(`${DNAME}/getgames`);
      if (result && result.data) {
        const game = result.data.find(g => g.name.toLowerCase() === gameName.toLowerCase());
        setFilteredGame(game || null);
      } else {
        setFilteredGame(null);
      }
    } catch (error) {
      console.error('Error fetching games:', error);
      toast.error("Error fetching games");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const parseMonthYear = (monthYearString) => {
    const [monthName, year] = monthYearString.split('-');
    const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();
    return new Date(year, monthIndex);
  };

  const resultsByDate = monthlyResults?.reduce((acc, curr) => {
    const date = formatDate(curr.date);
    if (!acc[date]) {
      acc[date] = {};
    }
    acc[date][curr.gameId] = curr.result;
    return acc;
  }, {}) || {};

  const dates = Object.keys(resultsByDate).sort();

  const getResultByMonth = async (date) => {
    try {
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const result = await axios.get(`${DNAME}/getgameresultsbymonth`, {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      });
      if (result && result.data) {
        setMonthlyResults(result.data);
      }
    } catch (error) {
      console.error('Error fetching game results by month:', error);
      toast.error('Error fetching game results by month');
    }
  };

  const handleDateChange = (event) => {
    const [year, month] = event.target.value.split('-');
    const newDate = new Date(year, month - 1);
    const formattedMonth = newDate.toLocaleString('default', { month: 'long' });
    const formattedYear = newDate.getFullYear();
    const formattedURL = `/${gameSlug}/${slugify(formattedMonth)}-${formattedYear}`;
    navigate(formattedURL);
  };

  const formattedMonthYear = selectedDate.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <Layout title={`${gameName} Lucky-Satta  Monthly Result for ${formattedMonthYear} `} description={`${gameName} Satta, a popular Satta King game. Check daily charts, and get the latest results on Lucky-Satta.co`}>
      <div className="p-4 font-bold text-4xl border-2 border-white heading flex justify-center items-center">
        <input
          type="month"
          value={`${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}`}
          onChange={handleDateChange}
          className="text-black p-2 rounded text-xl sm:text-2xl"
        />
      </div>
      <div className='overflow-x-auto'>
      <h1 className='h-20 heading font-bold text-xl sm:text-2xl flex justify-center items-center'>
      {gameName} Lucky-Satta  Monthly Result for {formattedMonthYear}
        </h1>
        <table className="border-collapse sm:w-[50%] sm:mx-auto table-auto">
          <thead>

            <tr>
              <th className="resulttableIstcol border border-slate-400 p-2 w-[50%]">Date</th>
              {filteredGame && (
                <th className="border border-slate-400 p-2 resulttableIstcol">{filteredGame.code}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {dates.map((date, index) => (
              <tr key={index}>
                <td className="border border-slate-400 p-2 text-center resulttableIstcol w-auto">{date}</td>
                {filteredGame && (
                  <td className="border border-slate-400 p-2 text-center">
                    {resultsByDate[date] && resultsByDate[date][filteredGame._id] ? resultsByDate[date][filteredGame._id] : '-'}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <GameDescription game={gameName} month={month}/>
      <Footer />
      <ToastContainer />
    </Layout>
  );
}

export default SingleGameChart;
