import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import './BlogCards.css'
import Layout from '../Layout/Layout';
const API_URL = process.env.REACT_APP_DNAME;

function BlogCards() {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${API_URL}/get-blogs`);
      setBlogs(response.data);
    } catch (error) {
      toast.error('Failed to load blogs');
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleCardClick = (blog) => {
    // Replace spaces with hyphens in the slug
    const formattedSlug = blog.slug.replace(/\s+/g, '-');
    navigate(`/blog/${formattedSlug}`, { state: { data: blog } });
  };

  return (
    
    <Layout>

    <div className="grid-container">
      {blogs.map((blog) => (
          <div
          key={blog.slug}
          className="blog-card"
          onClick={() => handleCardClick(blog)}
          >
          <img src={blog.imageLink} alt={blog.featuredImageAlt} className="blog-image" />
          <div className="blog-content">
            <h3>{blog.title}</h3>
            <p>{blog.metaDescription}</p>
          </div>
        </div>
      ))}
    </div>
      </Layout>
   
  );
}

export default BlogCards;
