import React from 'react';

const Header = () => {
  return (
    <header className="bg-yellow-300 text-center p-3 w-full text-sm">
      <h1 className="text-black ">@ 2024 Lucky Satta :: ALL RIGHTS RESERVED</h1>
    </header>
  );
};

export default Header;
