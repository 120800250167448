import React from "react";
import whatsaaplogo from "./logo.png";
import Footer from "../Footer/Footer";
import Layout from "../Layout/Layout";
const Contact01 = () => {
  return (
    <Layout title={"Lucky-Satta Contact-Us"}>
      <div className=" flex justify-center items-center m-2">
        <div class="w-full md:w-2/4 border-dashed rounded-3xl bg-yellow-300 border-4 p-2 text-xl border-blue-600 text-center">
          <p>
            <strong>🙏 नमस्कार साथियो 🙏</strong>
          </p>
          <p>
            <strong>
              अपनी गेम का रिजल्ट हमारी web साइट पर लगवाने के लिए संपर्क
              करें।&nbsp; &nbsp;
            </strong>
          </p>
          <p>
            <strong>♕♕ Ishu Bhai ♕♕</strong>
          </p>
          <div className=" h-20 flex justify-center items-center">
            <a
              target="_blank"
              href="https://wa.me/+919034069655"
              id="body_contactwa"
            >
              <strong>
                <img
                  className="rounded-full h-16"
                  loading="lazy"
                  src={require("./logo.png")}
                  alt="Whatsapp to show game on this website"
                />
              </strong>
            </a>
          </div>

          <p className="text-sm">
            NOTE: &nbsp; इस नंबर पर लीक गेम नही मिलता गेम लेने वाले भाई कॉल या
            मैसेज न करें।

          </p>
          
        </div>
        
      </div>
      <div className=" flex justify-center items-center flex-col w-screen">
          <Footer />
          <div className="px-14 py-4 font-medium">
            !! DISCLAIMER:- https://lucky-satta.co is a non-commercial website.
            Viewing This Website Is Your Own Risk, All The Information Shown On
            Website Is Sponsored And We Warn You That Matka Gambling/Satta May
            Be Banned Or Illegal In Your Country..., We Are Not Responsible For
            Any Issues Or Scam..., We Respect All Country Rules/Laws... If You
            Not Agree With Our Site Disclaimer... Please Quit Our Site Right
            Now. Thank You.
          </div>
        </div>
    </Layout>
  );
};

export default Contact01;
