import React from 'react'

function GameInfo() {
  return (
    <div>
       <div className=' flex justify-center items-center m-2'>
        <div class="w-full  border-dashed rounded-3xl bg-yellow-300 border-4 p-2 text-xl border-orange-600 text-center">
        <p>🙏नमस्कार साथियो 🙏</p>
          <p>सीधे सट्टा कंपनी का No 1 खाईवाल</p>
          <p><strong>♕♕ Ishu bhai Online khaiwal ♕♕</strong></p>

          <p className='text-sm font-bold sm:text-lg'>बेगम बाजार --------- 11:50 AM</p>
          <p className='text-sm font-bold sm:text-lg'>ओल्ड फरीदाबाद --------- 12:30 AM</p>
          <p className='text-sm font-bold sm:text-lg'>सदर बाजार ------------ 01:20 PM</p>
          <p className='text-sm font-bold sm:text-lg'>ग्वालियर --------- 2:20 PM</p>
          <p className='text-sm font-bold sm:text-lg'>दिल्ली बाजार -------- 03:00 PM</p>
          <p className='text-sm font-bold sm:text-lg'>करणी बाजार ------------ 03:45 PM</p>
          <p className='text-sm font-bold sm:text-lg'>श्री गणेश ------------ 4:40 PM</p>
          <p className='text-sm font-bold sm:text-lg'>फरीदाबाद ----------- 6:15 PM</p>
          <p className='text-sm font-bold sm:text-lg'>इंदौर सिटी ---------- 7:20 PM</p>
          <p className='text-sm font-bold sm:text-lg'>गाज़ियाबाद ---------- 9:00 PM</p>
          <p className='text-sm font-bold sm:text-lg'>द्वारका -------------- 10:30 PM</p>
          <p className='text-sm font-bold sm:text-lg'>गली --------------- 11:55 PM</p>
          <p className='text-sm font-bold sm:text-lg'>दिसावर ------------ 3:00 AM</p>

          <p>
          ==================<br />
         ==================</p>
          <p><strong>🤑 Rate list 💸</strong></p>
          <p><strong>जोड़ी रेट 10-------970</strong></p>
          <p><strong>हरूफ रेट 100-----970</strong></p>

          <p><strong>♕♕ Ishu bhai Online khaiwal ♕♕</strong></p>
          <p><strong>Game play करने के लिये नीचे लिंक पर क्लिक करे</strong></p>

          <div className='h-20 flex justify-center items-center'>
            <div >
              <a className='hover:blue-800' target='_blank' href="https://wa.me/+919034069655" id="body_contactwa">
                {/* <strong> 👉https://wa.me/+919034069655</strong> */}
                </a>
            </div>

          </div>
          <div className=' h-20 flex justify-center items-center '>
            <a target='_blank' href="https://wa.me/+919034069655" id="body_contactwa"><strong>
              <img className='rounded-full h-16 ' loading="lazy" src={require("./logo.png")} alt="Whatsapp to show game on this website" /></strong></a>
          </div>

          <p className='text-sm'>NOTE: &nbsp; इस नंबर पर लीक गेम नही मिलता गेम लेने वाले भाई कॉल या मैसेज न करें।</p>
        </div>
      </div>

    </div>
  )
}

export default GameInfo

