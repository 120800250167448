import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';

const DNAME = process.env.REACT_APP_DNAME;

function ResultTable() {
  const [gamename, setGameName] = useState([]);
  const [yesterdayResults, setYesterdayResults] = useState([]);
  const [todayResults, setTodayResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getGames();
    getGameResults();
  }, []);

  const getGames = async () => {
    try {
      const result = await axios.get(`${DNAME}/getgames`);
      if (result && result.data) {
        const sortedGames = result.data.sort(
          (a, b) => a.showNumber - b.showNumber
        );
        setGameName(sortedGames);
      } else {
        setGameName([]);
      }
    } catch (error) {
      console.error("Error fetching games:", error);
      toast.error("Error fetching games");
    }
  };

  const getGameResults = async () => {
    try {
      const [todayResult, yesterdayResult] = await Promise.all([
        axios.get(`${DNAME}/getgameresults`, {
          params: { date: new Date().toISOString().split("T")[0] },
        }),
        axios.get(`${DNAME}/getgameresults`, {
          params: {
            date: new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .split("T")[0],
          },
        }),
      ]);

      
      if (todayResult && todayResult.data) {
        setTodayResults(todayResult.data);
      }
      if (yesterdayResult && yesterdayResult.data) {
        setYesterdayResults(yesterdayResult.data);
      }
    } catch (error) {
      console.error("Error fetching game results:", error);
      toast.error("Error fetching game results");
    }
  };

  const formatTime = (time) => {
    let [hour, minute] = time.split(":");
    let ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12; // Convert hour to 12-hour format
    return `${hour}:${minute} ${ampm}`;
  };

  const getResult = (results, gameId) => {
    const result = results.find((result) => result.gameId === gameId);
    return result ? result.result : null;
  };

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  };

  const navigateToChart = (game) => {
    console.log("game==>",game)
    if (!game || !game.name) {
      toast.error("Invalid game data");
      return;
    }

    const slugifiedGameName = slugify(game.name);
    const currentDate = new Date();
    const month = currentDate
      .toLocaleString("default", { month: "long" })
      .toLowerCase();
    const year = currentDate.getFullYear();
    // navigate(`/${slugifiedGameName}/${slugify(month)}-${year}`);
    navigate(`/${slugifiedGameName}`, { state: { gameId: game._id },});
  };

  console.log("yesterday results=>",yesterdayResults)
  return (
    <div className="">
      <table className="w-full overflow-x-auto">
        <thead>
          <tr className="bg-yellow-300 text-center">
            <th className="w-1/3 border border-black p-2 bg-yellow-300">
              सट्टा का नाम
            </th>
            <th className="w-1/3 border border-black p-2 bg-yellow-300">
              कल आया था
            </th>
            <th className="w-1/3 border border-black p-2 bg-yellow-300">
              आज का रिज़ल्ट
            </th>
          </tr>
        </thead>
        <tbody>
          {gamename?.map((item, idx) => (
            <tr className='text-center h-16 snap-start' key={idx}>
              <td className='w-1/3 uppercase border border-black resulttableIstcol'>
              <NavLink
  to={{
    pathname: `/${slugify(item.name)}`,
  }}
  state={{ gameId: item._id }}
  className="text-xl font-bold hover:text-blue-600 hover:cursor-pointer"
>
  {item.name}
</NavLink>

                <div>{formatTime(item.resultTime)}</div>
              </td>
              <td className="w-1/3 border border-black">
                <div className="text-xl font-bold">
                  {getResult(yesterdayResults, item._id) || "-"}
                </div>
              </td>
              <td className="border border-black">
                <div className="flex justify-center items-center h-full">
                  <div className="text-xl font-bold">
                    {getResult(todayResults, item._id) || (
                      <img
                        className="h-10"
                        src={require("./d.gif")}
                        alt="Loading"
                      />
                    )}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
}

export default ResultTable;
