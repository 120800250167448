import Contact01 from "./components/Contact/Contact01";
import HomePage from "./Pages/HomePage/HomePage";
import { Routes, Route, useLocation } from "react-router-dom";
import CustomMonthResult from "./components/CustomMonthResult/CustomMonthResult";
import SingleGameChart from "./components/SingleGameChart/SintgleGameChart";
import Login from "./components/Admin/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import AdminHome from "./components/Admin/Dashboard/Dashboard";
import Games from "./components/Admin/Games/Games";
import GameResult from "./components/Admin/Game Result/GameResult";
import AdManager from "./components/Admin/AdManager/AdManager";
import PostCategory from "./components/Admin/Post/PostCategory";
import Blog from "./components/Admin/Post/Blog";
import BlogCards from "./components/BlogCards/BlogCards";
import BlogDetail from "./components/BlogDetail/BlogDetail";
import GameYearlyChart from "./components/GameYearlyChart/GameYearlyChart";
import { useEffect } from "react";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
// import SiteMap from './components/SiteMap'
function App() {

  return (
    <div>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/feed" element={<HomePage />} />
      <Route path="/blogs" element={<BlogCards />} />
      <Route path="/blog/:slug" element={<BlogDetail />} />
      <Route path="/contact-us" element={<Contact01 />} />
      <Route path="/custom-monthly-chart/:month" element={<CustomMonthResult />}/>
      <Route path="/:game-chart/:month" element={<SingleGameChart />} />
      <Route path="/:game-chart" element={<GameYearlyChart />} />
      {/* <Route path="/sitemap.xml" element={<SiteMap />} /> */}

      <Route path="/admin-login" element={<Login />} />
      <Route path="/admin/" element={<ProtectedRoute />}>
        <Route path="" element={<AdminHome />} />
        <Route path="games" element={<Games />} />
        <Route path="games-result" element={<GameResult />} />
        <Route path="ad-manager" element={<AdManager />} />
        <Route path="post-category" element={<PostCategory />} />
        <Route path="create-post" element={<Blog />} />
      </Route>
    </Routes>
    </div>
  );
}

export default App;
