import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DNAME = process.env.REACT_APP_DNAME;

function HeadingGames() {
  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [todayResults, setTodayResults] = useState([]);
  const [yesterdayResults, setYesterdayResults] = useState([]);

  useEffect(() => {
    getGames();
    getGameResults();
  }, []);

  useEffect(() => {
    filterGames();
  }, [games, todayResults, yesterdayResults]);

  const getGames = async () => {
    try {
      const result = await axios.get(`${DNAME}/getgames`);
      if (result && result.data) {
        setGames(result.data);
      } else {
        setGames([]);
      }
    } catch (error) {
      console.error('Error fetching games:', error);
      toast.error("Error fetching games");
    }
  };

  const getGameResults = async () => {
    try {
      const [todayResult, yesterdayResult] = await Promise.all([
        axios.get(`${DNAME}/getgameresults`, { params: { date: new Date().toISOString().split('T')[0] } }),
        axios.get(`${DNAME}/getgameresults`, { params: { date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0] } })
      ]);

      if (todayResult && todayResult.data) {
        setTodayResults(todayResult.data);
      }
      if (yesterdayResult && yesterdayResult.data) {
        setYesterdayResults(yesterdayResult.data);
      }
    } catch (error) {
      console.error('Error fetching game results:', error);
      toast.error('Error fetching game results');
    }
  };

  const filterGames = () => {
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();

    const parsedGames = games.map(game => {
      const [hours, minutes] = game.resultTime.split(':').map(Number);
      const gameMinutes = hours * 60 + minutes;
      return { ...game, gameMinutes };
    });

    const passedGames = parsedGames.filter(game => game.gameMinutes < currentMinutes);
    const upcomingGames = parsedGames.filter(game => game.gameMinutes >= currentMinutes);

    const latestPassedGame = passedGames.sort((a, b) => b.gameMinutes - a.gameMinutes)[0];
    const nextUpcomingGame = upcomingGames.sort((a, b) => a.gameMinutes - b.gameMinutes)[0];

    const filtered = [latestPassedGame, nextUpcomingGame].filter(Boolean);

    const mergedGames = filtered.map(game => {
      const todayResult = todayResults.find(result => result.gameId === game._id);
      const yesterdayResult = yesterdayResults.find(result => result.gameId === game._id);
      return {
        ...game,
        todayResult: todayResult ? todayResult.result :  <img className='h-10' src={require("./d.gif")} alt="Loading" />,
        yesterdayResult: yesterdayResult ? yesterdayResult.result :  <img className='h-10' src={require("./d.gif")} alt="Loading" />
      };
    });

    setFilteredGames(mergedGames);
  };

  return (
    <>
      
      {filteredGames.map((game, idx) => (
        <div key={idx} className='flex justify-center items-center flex-col  h-32'>
          <div className='font-bold uppercase text-orange-600 text-4xl'>{game.name}</div>
          <div className='flex justify-between items-center w-40'>
            <span className='font-bold  text-3xl'>{game.yesterdayResult}</span>
            <span className='text-red-600'> <img src={require("./teer.gif")} className="h-6" /></span>
            <span className='font-bold  text-3xl'>{game.todayResult}</span>
          </div>
        </div>
      ))}
    </>
  );
}

export default HeadingGames;
